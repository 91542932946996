<template>
	<div class="container">
		<common-header :activeIndex="0" @handleAsideToggle="handleAsideToggle" @handleChangeLang="getQuantizationMenu"></common-header>
		<transition><aside-left v-show="asideShow" @handleAsideHide="handleAsideHide"></aside-left></transition>

		<div class="layoutBox">
			<div class="swipeBanner">
				<van-swipe :autoplay="3000" :show-indicators="false">
					<van-swipe-item v-for="(item, index) in bannerList" :key="index" @click="lunbo_To()">
						<img class="banner" :src="item.url" />
						<!-- <img class="banner" src="@/assets/imgs/index/home_banner.png" /> -->
					</van-swipe-item>
				</van-swipe>
			</div>
			<div class="swipeNotice" v-if="noticeList.length">
				<!-- 				<div>
					<img class="xiaoxi_logo" src="../../assets/imgs/new_color/xiaoxi.png" />
				</div> -->
				<van-swipe :autoplay="3000" :show-indicators="false" vertical>
					<!-- <van-swipe-item v-for="(item, index) in noticeList" :key="index" @click="handleClickArticle(item.id)"> -->
					<van-swipe-item v-for="(item, index) in noticeList" :key="index" @click="routerTo('/consultingService')">{{ item.title }}</van-swipe-item>
				</van-swipe>
			</div>

			<div class="contWhiteBox menuBox">
				<menu-list :label="$t('tba.APIAuthorization')" linkUrl="/apiAuthorization2"><img slot="icon" src="@/assets/imgs/home/new_api.png" alt="" /></menu-list>
				<menu-list :label="$t('xinzeng.qianbao')" linkUrl="/assets"><img slot="icon" src="@/assets/imgs/home/new_qianbao.png" alt="" /></menu-list>

				<menu-list v-if="vipInfo.active" :label="$t('tba.VIPmember')" linkUrl="/memberClub"><img slot="icon" src="@/assets/imgs/home/new_vip.png" alt="" /></menu-list>
				<menu-list v-else :label="$t('tba.VIPmember')" linkUrl="/tobeVip"><img slot="icon" src="@/assets/imgs/home/new_vip.png" alt="" /></menu-list>

				<menu-list v-if="vipInfo.active" :label="$t('tba.invitefriends')" linkUrl="/sharePage">
					<img slot="icon" src="@/assets/imgs/home/new_yaoqinghaoyou.png" alt="" />
				</menu-list>
				<menu-list v-else :label="$t('tba.invitefriends')" linkUrl="/tobeVip"><img slot="icon" src="@/assets/imgs/home/new_yaoqinghaoyou.png" alt="" /></menu-list>

				<menu-list :label="$t('tba.myteam')" linkUrl="/community2"><img slot="icon" src="@/assets/imgs/home/new_myteam.png" alt="" /></menu-list>
				<menu-list :label="$t('tba.Log')" linkUrl="/journal"><img slot="icon" src="@/assets/imgs/home/new_rizi.png" alt="" /></menu-list>
				<menu-list :label="$t('tba.Bill')" linkUrl="/bill"><img slot="icon" src="@/assets/imgs/home/new_zhangdan.png" alt="" /></menu-list>
				<menu-list :label="$t('tba.trusteeship')" linkUrl="/new_trusteeship"><img slot="icon" src="@/assets/imgs/home/new_tuoguan.png" alt="" /></menu-list>
			</div>

			<div class="rankBox" v-if="rankList.length">
				<div class="caption">
					<div class="title mainFontColor">{{ $t('team.rankingList') }}</div>
					<!-- <span class="more mainFontLightColor" @click="routerTo('/rankingList2')">{{ $t('home.more') }} <i class="icon_arrR"></i></span> -->
				</div>
				<section class="rankCon" @click="routerTo('/rankingList2')">
					<div class="rankItem" style="width: 33%;" v-if="rankList[1]">
						<img class="crown" style="width:41px" src="@/assets/imgs/icon/ancrown2.png" alt="" />
						<img class="headImg" :src="rankList[1].icon" alt="" />
						<p class="name">{{ rankList[1].username }}</p>
						<p class="label">{{ $t('tba.today') }}</p>
						<p class="value">{{ rankList[1].amount }}</p>
						<p class="label">{{ $t('tba.addUp') }}</p>
						<p class="value">{{ rankList[1].tamount }}</p>
					</div>
					<div class="rankTop1Con" v-if="rankList[0]">
						<img class="shadow" src="@/assets/imgs/home/shadow.png" alt="" />
						<img class="shadow2" src="@/assets/imgs/home/shadow2.png" alt="" />
						<div class="rankItem guanjun" style="padding-top:6px">
							<img class="crown" style="width:60px" src="@/assets/imgs/icon/ancrown1.png" alt="" />
							<img class="headImg" style="width:48px;height:48px" :src="rankList[0].icon" alt="" />
							<p class="name">{{ rankList[0].username }}</p>
							<p class="label">{{ $t('tba.today') }}</p>
							<p class="value">{{ rankList[0].amount }}</p>
							<p class="label">{{ $t('tba.addUp') }}</p>
							<p class="value">{{ rankList[0].tamount }}</p>
						</div>
					</div>
					<div v-if="rankList[2]" class="rankItem1" style="width: 33%;">
						<img class="crown" style="width:32px" src="@/assets/imgs/icon/ancrown3.png" alt="" />
						<img class="headImg" :src="rankList[2].icon" alt="" />
						<p class="name">{{ rankList[2].username }}</p>
						<p class="label">{{ $t('tba.today') }}</p>
						<p class="value">{{ rankList[2].amount }}</p>
						<p class="label">{{ $t('tba.addUp') }}</p>
						<p class="value">{{ rankList[2].tamount }}</p>
					</div>
				</section>
			</div>

			<div class="contWhiteBox indexTabList">
				<van-row class="menuLine">
					<van-col span="24">
						<span class="menuItem" :class="{ on: exchange === 'huobi' }" @click="handleChangeExchange('huobi')">HUOBI</span>
						<span class="menuItem" :class="{ on: exchange === 'bian' }" @click="handleChangeExchange('bian')">BINANCE</span>
						<span class="menuItem" :class="{ on: exchange === 'okex' }" @click="handleChangeExchange('okex')">OKEx</span>
						<span class="menuItem" :class="{ on: exchange === 'uniswap' }" @click="handleChangeExchange('uniswap')">UNISWAP</span>
					</van-col>
				</van-row>
				<van-row class="menuLine">
					<van-col span="24">
						<span class="menuItem" :class="{ on: fangshi == item.value }" @click="jiaoyi_select(item.value)" v-for="(item, index) in menuList" :key="index">
							{{ jiaoyileixing(item.name) }}
						</span>
					</van-col>
					<van-popover
						v-model="showPopover"
						trigger="click"
						:actions="actions"
						@select="onSelect"
						placement="bottom-end"
						:offset="[20, 20]"
						style="position: absolute;
						right: 20px;
						top: 12.5px;"
						v-if="fangshi == 1"
					>
						<template #reference>
							<div class="dropdownBtn">
								{{ action }}
								<img src="@/assets/imgs/home/DropdownMenuIcon.png" style="width:11px;vertical-align: middle;" alt="" />
							</div>
						</template>
					</van-popover>
					<van-popover
						v-model="showPopover1"
						trigger="click"
						:actions="actions1"
						@select="onSelect1"
						placement="bottom-end"
						:offset="[20, 20]"
						style="position: absolute;
						right: 20px;
						top: 12.5px;"
						v-if="fangshi == 2"
					>
						<template #reference>
							<div class="dropdownBtn">
								{{ action1 }}
								<img src="@/assets/imgs/home/DropdownMenuIcon.png" style="width:11px;vertical-align: middle;" alt="" />
							</div>
						</template>
					</van-popover>
				</van-row>
				<search style="margin-top:10px" :placeholder="$t('tba.Pleaseentercurrency')" @onSearch="handleSearch" />
				<div class="homeList">
					<div v-if="showOrHidden" class="indexLoading">
						<van-loading size="24px">{{ $t('tba.Loading') }}</van-loading>
					</div>
					<div v-else>
						<div v-if="computedListData.length">
							<div class="item_head">
								<div class="name">
									<span @click="P_name">{{ $t('tba.DealFor') }}</span>
									<img
										src="../../assets/imgs/index/paixu.png"
										style="
									     width: 0.3rem;
									 "
									/>
									<!-- <span class="price" @click="P_price">/{{ $t('tba.price') }} </span>
						            <img
						             src="../../assets/imgs/index/paixu.png"
						             style="
						                 width: 0.3rem;
						             "
						             /> -->
								</div>
								<div class="bucang">
									<span>{{ $t('tba.Replenishmenttimes') }}</span>
								</div>
								<div class="fuyin">
									<span>{{ $t('tba.Floating') }}</span>
								</div>
								<div class="fudu">
									<span class="du" @click="P_fudu">{{ $t('tba.Up') }}</span>
									<img
										src="../../assets/imgs/index/paixu.png"
										style="
						                 width: 0.3rem;
						             "
									/>
								</div>
							</div>
							<div class="listItem" v-for="(item, index) in computedListData" :key="index" @click="robotStrategy(item)">
								<div class="list_top">
									<div class="listTitle">
										<div class="listTitle_top">
											<div class="top_left">
												<span class="listName">{{ item.currency_pair | namefilter }}</span>
												<span class="listName1">/{{ item.currency_pair | namefilter1 }}</span>
											</div>
											<div class="top_right" :class="{'duo':item.duokong == 1,'kong':item.duokong == 2}" v-if="item.currency_pair.indexOf('/SWAP') != -1">{{duokong_filter(item.duokong)}}x{{item.beishu}}</div>
										</div>
										<div class="listTitle_bottom">
											<div
												class="new_price"
												:class="{
													new_price_red: item.color === 'red',
													new_price_green: item.color !== 'red'
												}"
											>
												{{ item.last }}
											</div>
											<div class="listType color_madin">{{ $t('tba.' + item.type) }}</div>
										</div>
									</div>
									<div class="listDesc">
										<span>{{ item.times }}/{{ item.times_total }}</span>
									</div>
									<div class="listfuyin">
										<div class="fuying">{{ item.num_ }}</div>
										<div
											class="fuying_dian"
											:class="{
												red: item.color === 'red',
												green: item.color !== 'red'
											}"
										>
											{{ item.win_num }}
										</div>
									</div>
									<div
										class="listRatio"
										:class="{
											red: item.color === 'red',
											green: item.color !== 'red'
										}"
									>
										{{ item.ratio }}
									</div>
								</div>
								<div class="list_bottom" v-if="item.error != ''">
									<div class="dian"></div>
									<span class="err_msg">{{ item.error }}</span>
								</div>
							</div>
						</div>
						<!-- <empty v-else :exchange="exchange" style="margin-top: 0.5rem"></empty> -->
						<empty v-else style="margin-top: 0.5rem"></empty>
					</div>
				</div>
			</div>
		</div>

		<!-- 底部信息栏 -->
		<div class="bottomTips">
			<span>{{ $t('tba.Usdtavailable') }}: {{ balance }}</span>
			<span @click="routerTo('/quantification')">{{ $t('tba.dealNow') }}</span>
		</div>
	</div>
</template>

<script>
import commonHeader from '@/components/commonHeader';
import asideLeft from '@/components/asideLeft';
import search from '@/components/search';
import menuList from './components/menuList';
import rankList from './components/rankList';

import empty from '@/components/empty';
export default {
	name: 'newHome',
	components: {
		commonHeader,
		asideLeft,
		menuList,
		rankList,
		empty,
		search
	},
	data() {
		return {
			scrollY: 0,
			jiaoyiLX: 'spot',
			menuList: [],
			searchTxt: '',
			action: '',
			action1: '',
			showPopover: false,
			showPopover1: false,
			fangshi: 1,
			actions: [{ text: this.$t('tba.all') }],
			actions1: [{ text: this.$t('tba.all') }],
			// active: 'huobi', //当前选择的交易所类型
			name_pai: false,
			price_pai: false,
			Pai: -1,
			showOrHidden: true,
			noticeList: [], //通知列表
			listData: [], //机器人列表
			// ---------20200411[start]--------
			asideShow: false,
			rankList: [], //排行榜列表
			balance: 0, //可用货币余额
			exchange: 'huobi', //默认交易所类型,当前选择的交易所类型
			exchangeMap: {
				huobi: 1,
				bian: 2,
				okex: 3,
				uniswap: 4
			},
			// 轮播图
			bannerList: [],
			vipInfo: {}
		};
	},
	created() {
		// this.getAssets()
		// ---------20200411[end]-------
		// 获取默认交易所信息
		let userinfo = JSON.parse(localStorage.getItem('userInfo'));
		// this.userInfo = userinfo
		console.log(userinfo);
		this.exchange = userinfo.default_exchange;
		let info = JSON.parse(localStorage.getItem('index_info'));
		if (info != null) {
			this.exchange = info.exchange;
			this.fangshi = info.fangshi;
			this.action = info.action;
			this.jiaoyiLX = info.jiaoyiLX;
		}
		console.log(info);
		this.getArticlelist();
		this.getRobotList();
		this.getClubMemberInfo();
		this.getBannerList();
		this.getQuantizationMenu();
		// ---------20200411[start]-------
		this.getRankList();
		this.getBalance();
	},
	mounted() {
		this.timer = setInterval(() => {
			this.getRobotList();
		}, 5000);
	},
	destroyed() {
		console.log(this.$route);
		clearInterval(this.timer);
		if (this.$route.path == '/circularStrategy') {
			let index_info = {
				exchange: this.exchange,
				fangshi: this.fangshi,
				action: this.action,
				jiaoyiLX: this.jiaoyiLX,
				fangshi: this.fangshi
			};
			localStorage.setItem('index_info', JSON.stringify(index_info));
		} else {
			localStorage.removeItem('index_info');
		}
	},
	filters: {
		ratioFormat(value) {
			value = value.replace('%', '');
			let f = parseFloat(value);
			let t = f.toFixed(2) + '%';
			return t;
		},
		namefilter(v) {
			let new_str = v.split('/');
			return new_str[0];
		},
		namefilter1(v) {
			let new_str = v.split('/');
			return new_str[1];
		}
	},
	watch: {
		data(newValue, oldValue) {}
	},
	computed: {
		sortfudu() {
			return this.sortByKey(this.listData.huobi, 'ratio');
		},
		sortfudu1() {
			return this.sortByKey(this.listData.bian, 'ratio');
		},
		sortfudu2() {
			return this.sortByKey(this.listData.okex, 'ratio');
		},
		sortprice() {
			return this.sortByKey(this.listData.huobi, 'last');
		},
		sortprice1() {
			return this.sortByKey(this.listData.bian, 'last');
		},
		sortprice2() {
			return this.sortByKey(this.listData.okex, 'last');
		},
		computedListData() {
			let data = [];
			if (this.fangshi == 1) {
				if (this.action === this.$t('tba.celue')) {
					data = this.listData[this.exchange].filter(el => el.currency.indexOf('_swap') == -1);
				} else {
					data = this.listData[this.exchange].filter(el => {
						return this.$t('tba.' + el.robot_strategy_en) === this.action && el.currency.indexOf('_swap') == -1;
					});
				}
			} else {
				if (this.action1 === this.$t('tba.celue')) {
					data = this.listData[this.exchange].filter(el => el.currency.indexOf('_swap') != -1);
				} else {
					data = this.listData[this.exchange].filter(el => this.$t('tba.' + el.robot_strategy_en) === this.action1 && el.currency.indexOf('_swap') != -1);
				}
			}
			if (this.searchTxt) {
				data = data.filter(el => el.token.toUpperCase().indexOf(this.searchTxt) !== -1);
			}
			return data;
		}
	},
	methods: {
		duokong_filter(v) {
			if(v == 1) {
				return this.$t('tba.duo')
			}else if(v == 2) {
				return this.$t('tba.kong')
			}
		},
		jiaoyileixing(e) {
			if (e == '现货') {
				return this.$t('tba.xianhuojiaoyi');
			} else if (e == '合约') {
				return this.$t('tba.heyuejiaoyi');
			}
		},
		handleSearch(str) {
			if (/^[a-zA-Z]+$/.test(str)) {
				this.searchTxt = str.toUpperCase();
			} else {
				this.searchTxt = str;
			}
		},
		onSelect(e) {
			if (e.text === this.$t('tba.all')) {
				this.action = this.$t('tba.celue');
			} else {
				this.action = e.text;
			}
		},
		onSelect1(e) {
			if (e.text === this.$t('tba.all')) {
				this.action1 = this.$t('tba.celue');
			} else {
				this.action1 = e.text;
			}
		},
		jiaoyi_select(e) {
			// this.fangshi = fangshi;
			// this.$toast(this.$t('tba.kaifazhong'));
			// return;
			this.fangshi = e;
			if (this.fangshi == 2) {
				this.jiaoyiLX = 'swap';
			} else if (this.fangshi == 1) {
				this.jiaoyiLX = 'spot';
			}
			this.getBalance();
			// this.getRobotList();
		},
		sortByKey(array, key) {
			return array.sort((a, b) => {
				let x = a[key];
				let y = b[key];
				return x < y ? -1 : x > y ? 1 : 0; //小到大
			});
		},
		compare(item1, item2) {
			//item1 ，item2  默认参数  代表arr中的每一个值
			let val1 = item1.currency_pair; //利用item.name进行排序
			let val2 = item2.currency_pair;
			return val1 && val1.localeCompare(val2, 'zh-CN');
			// item1不能为空 ，否则会报localeCompare of undefined
		},
		P_name() {
			this.Pai = 0;
			this.name_pai = !this.name_pai;
			console.log(this.name_pai);
			if (this.name_pai == true) {
				this.listData.huobi = this.listData.huobi.sort(this.compare);
				this.listData.bian = this.listData.bian.sort(this.compare);
				this.listData.okex = this.listData.okex.sort(this.compare);
			} else {
				this.listData.huobi = this.listData.huobi.sort(this.compare).reverse();
				this.listData.bian = this.listData.bian.sort(this.compare).reverse();
				this.listData.okex = this.listData.okex.sort(this.compare).reverse();
			}
		},
		// P_price() {
		//     this.price_pai = !this.price_pai;
		//     console.log(this.price_pai);
		//     if(this.price_pai == true) {
		//         this.listData.huobi = this.sortprice
		// 		this.listData.bian = this.sortprice1
		// 		this.listData.okex = this.sortprice2
		//     }else {
		//         this.listData.huobi = this.sortprice.reverse();
		// 		this.listData.bian = this.sortprice1.reverse();
		// 		this.listData.okex = this.sortprice2.reverse();
		//     }
		// },
		P_fudu() {
			this.Pai = 1;
			this.price_pai = !this.price_pai;
			console.log(this.price_pai);
			if (this.price_pai == true) {
				this.listData.huobi = this.sortfudu;
				this.listData.bian = this.sortfudu1;
				this.listData.okex = this.sortfudu2;
			} else {
				this.listData.huobi = this.sortfudu.reverse();
				this.listData.bian = this.sortfudu1.reverse();
				this.listData.okex = this.sortfudu2.reverse();
			}
		},
		// 验证是否为会员用户，获取用户会员等级信息
		getClubMemberInfo() {
			this.$post2('User/Api/Index/getClubInfo', { noToast: true }).then(res => {
				this.vipInfo = res;
				// console.log('vipInfo', this.vipInfo)
			});
		},
		// // 获取资产信息
		// getAssets() {
		//   this.$post2("User/Api/Index/userAsset").then((res) => {
		//     console.log(res);
		//     // 总资产是 amout + frozen

		//     let t = res.USDT.amount + res.USDT.frozen;
		//     // this.assetsDitale = t.toFixed(2);
		//     this.balance = t.toFixed(2);
		//     // let t2 = res.REWARD.amount + res.REWARD.frozen;
		//     // this.rewardData = t2.toFixed(2);
		//     // console.log(typeof res.USDT.amount)
		//     // this.amount =  res.amount
		//     // + res.frozen
		//   });
		// },

		handleChangeExchange(exchange) {
			if (this.exchange !== exchange) {
				this.exchange = exchange;
				// 每次切换交易所,余额都跟随变动
				this.getBalance();
			}
		},
		// ----------20200413[start]---------
		// 通知新闻跳转
		handleClickArticle(id) {
			this.$router.push({
				path: '/viewArticle',
				query: {
					id: id
				}
			});
		},
		// ----------20200411[start]---------
		handleAsideToggle() {
			this.asideShow = !this.asideShow;
		},
		handleAsideHide() {
			this.asideShow = false;
		},
		routerTo(linkUrl) {
			this.$router.push(linkUrl);
		},

		// 获取公告List
		getArticlelist() {
			this.$post2('Article/Api/Index/getHomeArticle', { pagesize: 10 , noToast: true}).then(res => {
				this.noticeList = res.list;
			});
		},

		// 排行榜列表
		getRankList() {
			this.$post2('Trade/api/index/ranklist', {
				noToast: true
			})
				.then(res => {
					// console.log(res);
					this.rankList = res.list;
				})
				.catch(e => {
					this.$toast.fail(e);
				});
		},

		// 获取-某一个-交易所的可用余额-传参-(交易所exchange=>1:huobi/2:bian/3:okex/4:uniswap)
		getBalance() {
			this.$post2('Robot/Api/Index/getBalance', {
				exchange: this.exchangeMap[this.exchange],
				type: this.jiaoyiLX,
				noToast: true
			})
				.then(res => {
					if (res.USDT.amount !== null) {
						this.balance = res.USDT.amount;
					}
				})
				.catch(e => {
					this.balance = 0
					// console.log("e222: ",e);
					//     this.$toast.clear()
				});
		},
		// 获取量化页面的筛查配置菜单
		getQuantizationMenu() {
			this.$post2('Robot/api/index/getQuantizationMenu', { noToast: true })
				.then(res => {
					this.actions = [];
					this.actions1 = [];
					this.action = this.$t('tba.celue');
					this.action1 = this.$t('tba.celue');
					this.menuList = res;
					let strategy_children = this.menuList[0].strategy_children;
					strategy_children.forEach(item => {
						this.actions.push({ text: this.$t('tba.' + item.value) });
					});
					let strategy_children1 = this.menuList[1].strategy_children;
					strategy_children1.forEach(item => {
						this.actions1.push({ text: this.$t('tba.' + item.value) });
					});
				})
				.catch(e => {
					// this.$toast.fail({
					// 	message: e,
					// 	duration: 3000
					// });
				});
		},
		// ----------20200411[end]---------
		// 初始化-获取交易所机器人列表
		getRobotList() {
			this.$post2('Robot/Api/Index/getRobotsListWithPrice', {	noToast: true })
				.then(res => {
					// if (res.length) {
					this.listData = {
						huobi: res.huobi || [],
						bian: res.bian || [],
						okex: res.okex || [],
						uniswap: res.uniswap || []
					};
					this.showOrHidden = false;
					if (this.Pai == 0) {
						if (this.name_pai == true) {
							this.listData.huobi = this.listData.huobi.sort(this.compare);
							this.listData.bian = this.listData.bian.sort(this.compare);
							this.listData.okex = this.listData.okex.sort(this.compare);
						} else {
							this.listData.huobi = this.listData.huobi.sort(this.compare).reverse();
							this.listData.bian = this.listData.bian.sort(this.compare).reverse();
							this.listData.okex = this.listData.okex.sort(this.compare).reverse();
						}
					} else if (this.Pai == 1) {
						if (this.price_pai == true) {
							this.listData.huobi = this.sortfudu;
							this.listData.bian = this.sortfudu1;
							this.listData.okex = this.sortfudu2;
						} else {
							this.listData.huobi = this.sortfudu.reverse();
							this.listData.bian = this.sortfudu1.reverse();
							this.listData.okex = this.sortfudu2.reverse();
						}
					}
					// }
				})
				.catch(e => {
					this.showOrHidden = false;
				});
		},
		// 获取banner 图
		getBannerList() {
			this.$post2('Pay/api/index/getBannerList ', { noToast: true })
				.then(res => {
					this.bannerList = res.list;
				})
				.catch(e => {});
		},

		// 跳转策略设置页面
		robotStrategy(item) {
			this.$router.push({
				path: '/circularStrategy',
				query: {
					bot_id: item.bot_id,
					exchange: this.exchange,
					homepath: '/newhome',
					robot: item.robot_strategy,
					fangshi: this.fangshi
				}
			});
		}
	}
};
</script>

<style lang="less">
.newHomeTwo {
	.van-swipe__indicators {
		width: 22%;
		left: 50%;
		bottom: 0.16rem;
	}

	.van-swipe__indicator {
		height: 0.04rem;
		border-right: 0;
		flex: 1;
	}

	.van-swipe__indicator:not(:last-child) {
		margin-right: 0;
	}
}
</style>
<style lang="less" scoped>
.homeList {
	padding-bottom: 1.2rem;
	.item_head {
		padding: 0.4rem 0 0.2rem 0;
		display: flex;
		font-family: PingFang SC;
		justify-content: space-between;
		align-items: center;
		font-size: 0.29rem;
		color: #3893fb;
		// flex: 1;
		> div {
			padding: 2px 5px;
			background-color: #f3f8fe;
			border-radius: 4px;
		}

		.name {
			// flex: 1.6;
			// width: 33%;
			padding: 0.06rem 0.15rem;
			text-align: left;
			display: flex;
			align-items: center;
		}

		.bucang {
			padding: 0.06rem 0.15rem;
			margin-left: 0.3rem;
			// flex: 1;
			// width: 17%;
			text-align: left;
			// margin-left: 0.5rem;
			span {
				// margin-left: 0.3rem;
			}
		}
		.fuyin {
			padding: 0.06rem 0.15rem;
			// flex: 1;
			// width: 20%;
			text-align: left;
			span {
				// margin-left: 0.3rem;
			}
		}

		.fudu {
			padding: 0.06rem 0.15rem;
			// flex: 1;
			// width: 30%;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			// .du {
			//     padding-right: 0.15rem;
			// }
		}
	}

	.listItem {
		// position: relative;
		// margin-top: 0.2rem;
		padding: 0.2rem 0 0.2rem 0;
		border-bottom: 1px solid #f4f4f4;
		.list_top {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.listTitle {
				// margin-top: 0.4rem;
				// flex: 1.6;
				// width: 38%;
				width: 2.8rem;
				.listTitle_top {
					// position: relative;
					display: flex;
					// justify-content: space-between;
					align-items: center;
					.top_right {
						margin-left: 0.12rem;
						font-size: 0.24rem;
						transform: scale(0.83);
						padding: 0 0.05rem;
						border-radius: 0.04rem;
						text-align: center;
					}
					.duo {
						background-color: rgba(50, 191, 136, 0.09);
						color: #32BF88;
					}
					.kong {
						background-color: rgba(245, 70, 93, 0.09);
						color: #F5465D;
					}
				}
				.listName {
					font-size: 0.36rem;
					font-family: Bahnschrift;

					color: #181818;
				}
				.listName1 {
					padding-left: 0.05rem;
					font-size: 0.26rem;
					font-family: Bahnschrift;
					font-weight: bold;
					color: #999;
				}

				.listName_right {
					font-size: 0.12rem;
					font-family: Bahnschrift;
					font-weight: bold;
					color: #999999;
					padding-left: 0.05rem;
				}

				.listTitle_bottom {
					padding: 0.1rem 0.2rem 0.1rem 0;
					display: flex;
					height: 0.5rem;
					align-items: center;
					justify-content: space-between;

					.new_price {
						white-space: nowrap;
						line-height: 0.5rem;
						font-size: 0.28rem;
						font-family: Bahnschrift;
						font-weight: bold;
					}

					.new_price_red {
						color: #cf4e65;
					}

					.new_price_green {
						color: #07ad91;
					}
				}

				.listType {
					height: 0.3;
					line-height: 0.3rem;
					font-size: 0.24rem;
					font-family: PingFang SC;
					color: #5eacff;
					border-radius: 0.04rem;
					background-color: #f7faff;
					// padding: 1px 2px;
					margin-left: 0.1rem;
				}
				.color_madin {
					white-space: nowrap;
					border: 1px solid #3893fb;
					color: #3893fb;
					padding: 2px;
				}
				.color_dibu {
					border: 1px solid #00bff1;
					color: #00bff1;
				}
				.color_hailang {
					border: 1px solid #5600f8;
					color: #5600f8;
				}
			}

			.listDesc {
				// flex: 1;
				// width: 12%;
				text-align: center;
				margin-right: 0.3rem;
				white-space: nowrap;

				span {
					font-size: 0.28rem;
					font-family: PingFang SC;
					font-weight: 500;
					color: #02172e;
					font-weight: bold;
					// margin-left: 0.06rem;
					letter-spacing: 1px;
				}
				// span.red {
				//     color: #CF4E65;
				// }

				// span.green {
				//     color: #07AD91;
				// }
			}

			.fuying {
				color: #373737;
				font-size: 0.31rem;
				font-family: Bahnschrift;
				// margin-left: 0.25rem;
				// margin-left: 0.05rem;
				font-weight: bold;
				// margin-right: 0.4rem;
			}

			.fuying_dian {
				padding-top: 0.1rem;
				// margin-left: 0.05rem;
				font-size: 0.28rem;
				font-family: Bahnschrift;
				// margin-right: 0.4rem;
			}
			.fuying_dian.red {
				color: #cf4e65;
			}
			.fuying_dian.green {
				color: #07ad91;
			}
			.listfuyin {
				// flex: 1;
				// width: 25%;
				width: 1.3rem;
				text-align: right;
				margin-right: 0.35rem;
			}

			.listRatio {
				// flex: 1;
				// width: 30%;
				font-size: 0.31rem;
				font-family: PingFang SC;
				font-weight: bold;
				// position: absolute;
				// right: 0;
				// top: 0.3rem;
				color: #fff;
				padding: 0.15rem 0;
				border-radius: 0.12rem !important;
				width: 1.6rem;
				text-align: center;
			}

			.listRatio.red {
				background: #cf4e65;
				border-radius: 6px;
			}

			.listRatio.green {
				background: #07ad91;
				border-radius: 6px;
			}
		}
		.list_bottom {
			margin-top: 0.1rem;
			display: flex;
			align-items: center;
			.dian {
				margin-top: 0.02rem;
				width: 0.08rem;
				height: 0.08rem;
				background-color: #f5465d;
				border-radius: 50%;
				// margin-right: 0.1rem;
			}
			.err_msg {
				margin-left: -0.05rem;
				color: #f5465d;
				font-size: 0.24rem;
				transform: scale(0.83);
			}
		}
	}
}
</style>

<style scoped lang="less">
.layoutBox {
	padding: 0 0.3rem;
}
.menuBox {
	padding: 0.24rem 0 0.15rem;
	display: flex;
	flex-wrap: wrap;
	box-shadow: 0 2px 18px rgba(202, 202, 202, 1);
}
.swipeBanner {
	height: 1.7rem;
	margin-bottom: 0.15rem;
	overflow: hidden;
	.banner {
		width: 6.9rem;
		height: 1.7rem;
		display: block;
	}
}
.swipeNotice {
	background-color: #fff;
	color: #666666;
	font-size: 0.28rem;
	height: 0.62rem;
	line-height: 0.62rem;
	padding: 0 0.15rem 0 0.65rem;
	background: #fff url(../../assets/imgs/icon/home_icon_notice.png) no-repeat left 0.15rem center;
	background-size: 0.32rem 0.3rem;
	border-radius: 0.1rem;
	margin-bottom: 0.15rem;
	overflow: hidden;
	.icon_arrR {
		float: right;
		margin: 0.2rem 0.1rem 0 0.2rem;
	}
	.van-swipe-item {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}
.rankBox {
	margin-bottom: 0.25rem;
	.caption {
		display: flex;
		justify-content: space-between;
		align-items: center;
		// position: relative;
		height: 0.6rem;
		margin-bottom: 0.8rem;
		// &::before {
		// 	content: '';
		// 	width: .04rem;
		// 	height: .24rem;
		// 	background: #90C4FF;
		// 	border-radius: 2px;
		// 	position: absolute;
		// 	left: 0;
		// 	top: 50%;
		// 	margin-top: -.12rem;
		// }
		.title {
			font-size: 0.32rem;
			font-family: PingFang SC;
			// margin-left: .14rem;
		}
		.more {
			font-size: 0.24rem;
		}
	}
	.list {
		display: flex;
		// justify-content: space-between;
		align-items: flex-end;
	}
}

.indexTabList {
	padding: 0 10px;
	.tabTit {
		color: #999999;
		font-size: 0.32rem;
		font-family: 'Bahnschrift', 'PingFangSC-Regular', 'PingFang SC', '微软雅黑', 'Microsoft Yahei';
		line-height: 0.7rem;
		height: 0.7rem;
		border-bottom: 1px solid #ededed;
		display: flex;
		justify-content: space-between;
		margin-bottom: 0.1rem;
		text-transform: capitalize;
		li {
			padding-bottom: 0.68rem;
		}
		.on {
			color: #373737;
			font-size: 0.4rem;
			font-weight: bold;
			border-bottom: 1px solid #373737;
		}
	}
}
.menuLine {
	// margin: 0.33rem 0.245rem 0;
	padding: 8px 0;
	position: relative;

	.menuName {
		font-size: 0.24rem;
		color: #373737;
		display: inline-block;
		margin-top: 0.03rem;
	}

	.menuItem {
		font-size: 0.3rem;
		// font-weight: 600;
		color: #373737;
		opacity: 0.5;
		line-height: 0.6rem;
		// text-align: center;
		// padding: 0.01rem 0 0.02rem;
		// display: inline-block;
		// width: 25%;
		// padding-left: 0.2rem;
		padding: 0 0.3rem;
		min-width: 20%;
		position: relative;
		text-transform: capitalize;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		&:first-child {
			padding-left: 0;
			&::before {
				display: none;
			}
		}
		&:last-child {
			padding-right: 0;
		}
	}

	.menuItem:last-child {
		margin-right: 0;
	}

	.menuItem.on {
		// background: #fc9216;
		// color: #90c4ff;
		opacity: 1;
		font-weight: 700;
		font-size: 0.32rem;
		color: #3893fb;
		// border-right: 1px solid #fc9216;
	}
	.dropdownBtn {
		color: #3893fb;
		font-size: 0.32rem;
	}
}

.indexLoading {
	text-align: center;
	padding-top: 1rem;
}

.bottomTips {
	color: #3893fb;
	font-size: 0.24rem;
	text-transform: uppercase;
	padding: 0 0.3rem;
	width: 100%;
	height: 0.52rem;
	line-height: 0.52rem;
	background: #f3f8ff;
	display: flex;
	justify-content: space-between;
	position: fixed;
	left: 0;
	bottom: 0;
}
.list-item0 {
	margin-left: 0.2rem;
}
.list-item2 {
	margin-left: 0.2rem;
}
.container {
	background-color: #f5f5f5;
}
.new_list {
	font-size: 12px;
	font-family: PingFang SC;
	color: #ffffff;
	padding: 0.2rem 0.4rem 0.2rem 0.3rem;
	border-radius: 6px;
	background-image: linear-gradient(to right, #8ec4ff, #ddacff);
	background-color: #8ec4ff;
	// FILTER: progid:DXImageTransform.Microsoft.Gradient(GradientType=0, StartColorStr=#8EC4FF, EndColorStr=#DDACFF); /*IE6-IE8*/
	// background-image: -ms-linear-gradient(right, #8EC4FF,  #DDACFF);        /* IE 10 */
	// background-image:-moz-linear-gradient(right, #8EC4FF,#DDACFF);/*火狐*/
	// background-image:-webkit-gradient(linear, 0% 100%, 0% 100%,from(#8EC4FF), to(#DDACFF));/*谷歌*/
	// background-image: -webkit-gradient(linear, 0% 100%, 0% 100%, from(#8EC4FF), to(#DDACFF));      /* Safari 4-5, Chrome 1-9*/
	// background-image: -webkit-linear-gradient(right, #8EC4FF, #DDACFF);   /*Safari5.1 Chrome 10+*/
	// background-image: -o-linear-gradient(right, #8EC4FF, #DDACFF);  /*Opera 11.10+*/
	.leijishouyi {
		margin-bottom: 0.1rem;
	}
	.list_item {
		padding: 0.1rem 0;
		display: flex;
		align-items: center;
		.phone {
			width: 35%;
		}
		.leiji {
			width: 33%;
		}
		.jinri {
			width: 32%;
		}
	}
}
.rankCon {
	height: 3.3rem;
	background-color: #fff;
	border-radius: 6px;
	// display: flex;
	position: relative;
	.rankItem {
		position: absolute;
		left: 0;
		bottom: 0;
		text-align: center;
		// position: relative;
		.crown {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, -70%);
		}
		.headImg {
			width: 39px;
			height: 39px;
			border-radius: 50%;
			object-fit: cover;
			margin-bottom: 7px;
		}
		.name {
			color: #3893fb;
			font-size: 12px;
			margin-bottom: 15px;
		}
		.label {
			color: #666666;
			font-size: 10px;
		}
		.value {
			font-size: 16px;
			color: #333;
			margin-bottom: 8px;
		}
	}
	.rankItem1 {
		position: absolute;
		right: 0;
		bottom: 0;
		text-align: center;
		// position: relative;
		.crown {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, -70%);
		}
		.headImg {
			width: 39px;
			height: 39px;
			border-radius: 50%;
			object-fit: cover;
			margin-bottom: 7px;
		}
		.name {
			color: #3893fb;
			font-size: 12px;
			margin-bottom: 15px;
		}
		.label {
			color: #666666;
			font-size: 10px;
		}
		.value {
			font-size: 16px;
			color: #333;
			margin-bottom: 8px;
		}
	}
	.rankTop1Con {
		width: 34%;
		margin: 0 auto;
		position: relative;
		.shadow {
			width: 8px;
			top: -4px;
			left: -8px;
			position: absolute;
		}
		.shadow2 {
			width: 8px;
			top: -4px;
			right: -8px;
			position: absolute;
		}
		.guanjun {
			background-color: #fff;
			position: absolute;
			top: -15px;
			border-radius: 8px;
			width: 100%;
			bottom: 0;
		}
	}
}
</style>
